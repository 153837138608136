



















































































































import { Vue, Component, Prop, Watch } from 'vue-class-decorator'
import { GlobalModule, UserModule, SchedulesModule } from '@/store'
import { getWeekByDate } from '@/utilities'

@Component({
})
export default class Lateness extends Vue {
  $refs!: {
    datePickerMenu: HTMLFormElement;
  }

  public dates: Array<string> | null
  public datePickerMenu: boolean
  public userId: number
  constructor() {
    super()
    this.dates = null
    this.datePickerMenu = false
    this.userId = 1
  }

  @SchedulesModule.Action('requestLateness') requestLateness!: (payload) => Promise<void>
  @GlobalModule.Getter('getLoading') loading!: boolean
  @SchedulesModule.Getter('getUsers') users!: Array<object>
  @SchedulesModule.Getter('getLateness') lateness!: { [propName: string]: any }

  /* eslint-disable @typescript-eslint/camelcase */
  // Watch
  @Watch('dates') changeDates() {
    if (this.dates && this.dates[0] && !this.dates[1]) {
      this.dates = getWeekByDate(this.dates[0])
    }
  }

  @Watch('userId') changeUser() {
    this.requestLateness({ dates: this.dates, user_id: this.userId })
  }

  get datesText() {
    return this.dates ? this.dates.join(' ~ ') : null
  }

  getActualDate() {
    const d = new Date()
    return d.toJSON().substring(0, 10)
  }

  changeDate() {
    this.$refs.datePickerMenu.save(this.dates)

    this.requestLateness({ dates: this.dates, user_id: this.userId })
  }

  created() {
    this.dates = getWeekByDate()
    this.requestLateness({ dates: this.dates, user_id: this.userId })
  }
  /* eslint-enable @typescript-eslint/camelcase */
}
